canvas#barchart {
    width: 1100px !important;
    height: 500px !important;
    margin-left: 135px ;
    border: 4px solid darkslategray;
    padding-left: 50px;
    padding-right:100px;
    padding-top: 30px;
    margin-bottom: 40px;
}

.pad {
    border-radius: 21px;
}
.pie {
    padding-left: 35px;
    padding-right: 35px;
}
.polarArea {
    padding-left: 5px;
    padding-right: 5px;
}
.Radar {
    padding-left: 25px;
    padding-right: 25px;
}
.bar {
    padding-left: 35px;
    padding-right: 35px;
}
.doughnut {
    padding-left: 15px;
    padding-right: 15px;
}
.line {
    padding-left: 30px;
    padding-right: 30px;
}