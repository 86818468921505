.box.box-danger {
    border-top-color: #dd4b39;
}
.content {
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    border-top: 7px solid #62D27B;
}
.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.box-header>.fa, .box-header>.glyphicon, .box-header>.ion, .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
}
.bg-aqua, .callout.callout-info, .alert-info, .label-info, .modal-info .modal-body {
    background-color: #00c0ef !important;
}
.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.small-box>.inner {
    padding: 10px;
    width: 255px;
}
.small-box .icon {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0,0,0,0.15);
}
.small-box>.small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255,255,255,0.8);
    display: block;
    z-index: 10;
    background: rgba(0,0,0,0.1);
    text-decoration: none;
    
}
.bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body {
    background-color: #00a65a !important;
}
.bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body {
    background-color: #dd4b39 !important;
}
.bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
    background-color: #f39c12 !important;
}

.color12{
    color: white;
}
.head{
    font-weight: 900;
    font-size: 32px;
}
.small-box>.small-box-footer:hover {
    color: #fff;
    background: rgba(0,0,0,0.15);
}

.icon i:hover {
    width: 100px;
    height: 100px;
    -webkit-transition: width 2s; /* Safari */
    transition: width 2s;

}
.box1 {
    border-top: 14px solid #62D27B;
    background: white;
  /* padding: 5px; */
}
.btnalig {
    /* text-align: right; */
    float: left;
    margin-top: 25px;
}
.width1 {
    margin: 10px;
}
.sokettablemr {

    margin-top: 5vh;
}
.takenclass {
    color: white;
    font-size: 11px;
}

.chart-container{
    position: relative; 
    height:60vh; 
    width:80vw
}
p.line2 {
    padding-left: 30px;
    padding-right: 30px;
    border: 1px #62D27B solid;
}
select#Page_Size {
    padding: 3px;
    border-radius: 4px;
}
span.spanchart {
    color: #337ab7;
}
/* @media (max-width: 1199px) and (min-width: 769px) {
    .custmDivSensor {
        width: max-content !important;
    }
    .wrapperSenSors {
        width: max-content !important;
    }
} */
@media (max-width: 768px) {
    /* For mobile phones: */
    /* .chart-container {
    
        height:60vh; 
        width:80vw
        
    } */
    .chart-container{
        /* position: relative; 
        height:60vh;  */
        width:92vw !important
    }
    .chartCantainer {
    margin-left:0px ;
    padding-left: 0px;
    }
    /* canvas#barchart1 {
        height: 289px !important;
        width: 362px !important;
    } */
    .ViewBredCum {
        height: 100px;
    } 
    .btnalig {
        /* text-align: right; */
        float: left;
        margin-top: 2px;
        margin-bottom: 20px;
    }
    /* .wrapperSenSors {
        width: max-content !important;
    }
    .custmDivSensor {
        width: max-content;
    } */
  }
.custmcolor1{
background: #00C0EF !important;

}
.custmcolor2{
    background: #2589BD !important;
    
}
    .custmcolor3{
        background: #3D5A80 !important;
     }



     .custmDivSensor {
        width: max-content !important;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
    }
    .wrapperSenSors {
        width: max-content !important;
        overflow: auto;
    }
    .custmDivSensorUpper {
        max-width: auto;
        overflow-x: auto;
    }

    .dashlastAlert {
        font-size: 18px;
        margin-top: 24px;
        /* margin-bottom: 44px; */
    }
    .fontsizeicon {
        font-size: 84px !important;
        top: -2px !important;
    }
    .ViewBredCum {
        /* margin-top: 10px; */
        background: aliceblue;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 7px;
    }
    .spanBredcum {
        font-weight: bold;
    }
    
.spanBredcumslash {
    margin-left: 5px;
    margin-right: 10px;
    font-weight: 900;
}
.navright {
    display: inline;
    float: right;
}
.spanNev {
    padding: 7px;
    border-radius: 50px;
    border: 1px solid #c1e1ac;
    cursor: pointer;
    background: transparent;
    color: #41503e;
    font-size: 14px !important;
    margin-left: 7px;
    margin-right: 7px;
}

.spanNev:hover,
.spanNev:active {
    background: #e0e3de !important;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}
.spanNev:focus {
    outline: 0px !important;
}
.criteriaClass {
    color: white;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.chartbtn {
    font-size: 26px !important;
    color: darkgreen;
    padding-bottom: 38px !important;
    padding-top: 1px !important;
    margin-bottom: 6px;
    background: white;
}
.mr-Bootom-2 {
    margin-bottom: 20px;
}
.align-right {
    float: right;
}
.align-center {
    text-align: center;
}
.custmpage {
    margin: 0px !important;
}
.startendTime {
    margin-left: 7px;
    margin-right: 7px;
    width: 80%;
}
.mb-2 {
    margin-bottom: 10px;
}
.ml-5 {
    margin-left: 20px;
}
.disabledClassLabel{
    color:#ccc;
}
.labeltoggal {
    padding-left: 25px;
   
}
.valuetoggal{
/* padding-left: 25px; */


}
.ml-6 {
    margin-left: 40px;
}
.font-12{
    font-size: 12px;
}
.color-grey{
    color: gray;
}
.commentIcon{
    font-size: 15px;
    
}
.spaceinComment {
    padding-left: 17px;
    padding-right: 17px;
}

.outerareaOfComment {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
}
.fontSize16 {
    font-size: 17px;

}
.commentCross{
    color: red !important;
}
.commentCross :hover  {
    color: #93301cde !important;
}
.commentPencel {
    color: black !important;
}
.commentPencel :hover  {
    color: #03030375 !important;
}
.outerContainer {
    max-height: 230px;
    overflow-y: auto;
}
.commentIcon{
    width: 24px;
    height: 22px;
    padding-top: 3px;
    border-radius: 100%;
    text-align: center;
}

.commentIcon:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    width: 24px;
    height: 22px;
    padding-top: 3px;
    border-radius: 100%;
    text-align: center;
}
.commentIcon:active,.commentIcon:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;

    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    width: 24px;
    height: 22px;
    padding-top: 3px;
    border-radius: 100%;
    text-align: center;
}

.commentBadge {
    /* padding-right: 12px; */
    background: rgb(98, 210, 123);
    /* width: 18px !important;
    height: 18px !important; */
    padding-top: 4px;
    border-radius: 100%;
    color: white;
    font-size: 11px;
    margin-top: -4px;
}
.saveComment {
    color: black;
    margin-right: 5px;
}

.cancelcomment {
    color: black;
    text-decoration: none !important;
}
.commentLeft {
    float: left;
}