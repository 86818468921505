.App11 {
  font-family: sans-serif;
  text-align: center;
}

.ciclegraph {
  position: relative;
  width: 360px;
  height: 250px;
  margin: calc(100px / 2 + 0px);
}

.ciclegraph:before {
  content: "";
  position: absolute;
  top: -100px;
  left: 275px;
  border: 2px solid teal;
  width: calc(100% - 2px * 2);
  height: calc(60% - 2px * 2);
  border-radius: 50%;
}

.ciclegraph .circle {
  position: relative;
  top: -0%;
  left: 150%;
  width: 50px;
  height: 50px;
  margin: calc(-200px / 2);
  background: rgb(0, 195, 255);
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font: 16px Arial, sans-serif;
}
.ciclegraphTwo {
  position: absolute;
  width: 360px;
  height: 240px;
  margin: calc(100px / 2 + 0px);
  /* background-color: gainsboro; */
}

.ciclegraphTwo:after {
  content: "";
  position: absolute;
  top: -280px;
  left: 275px;
  border: 2px solid teal;
  width: calc(100% - 2px * 2);
  height: calc(60% - 2px * 2);
  border-radius: 50%;
  background-color: rgb(219, 219, 221);
}

.ciclegraphTwo .circleTwo {
  position: absolute;
  top: -60%;
  left: 138%;
  width: 50px;
  height: 50px;
  margin: calc(-200px / 2);
  background: rgb(0, 195, 255);
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font: 16px Arial, sans-serif;
  z-index: 1;
}
.tree {
  width: 315px;
  height: 0px;
  z-index: 1;
  top: -85px;
  position: relative;
  left: 465px;
}
.temp {
  width: 50px;
  height: 50;
  top: 28px;
  position: relative;
  left: 120px;
  z-index: 2;
  size: 100px;
  font: 30px Arial, sans-serif;
}

.date {
  width: 800px;
  height: 50;
  top: -65px;
  position: relative;
  left: 90px;
  z-index: 2;
  size: 100px;
  font: 18px Arial, sans-serif;
  padding-bottom: 20px;
}

.depth {
  width: 500px;
  height: 50px;
  top: 50px;
  position: relative;
  left: 405px;
  size: 100px;
  font: 18px Arial, sans-serif;
  background-color: transparent;
  z-index: 1;
}
.depthTwo {
  width: 500px;
  height: 50;
  top: -70px;
  position: relative;
  left: 175px;
  size: 100px;
  font: 18px Arial, sans-serif;
  background-color: transparent;
  z-index: 1;

}

.notify {
  width: 500px;
  height: 50px;
  top: 50px;
  position: relative;
  left: 700px;
  size: 100px;
  font: 25px  Arial, sans-serif;
  font-weight: 'bold';
  background-color: transparent;
  color:#104503;
  z-index: 1;

}

.loginBtn {
  background-color: green;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  
  cursor: pointer;
  text-align: center;
  margin:  2em 8em;
}


.text {
  width: 500px;
  height: 50px;
  top: 50px;
  position: relative;
  left: 550px;
  size: 100px;
  font: 25px  Arial, sans-serif;
  font-weight: 'bold';
  background-color: transparent;
  color:#104503;
  z-index: 1;

}