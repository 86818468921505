body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Menu{
overflow: hidden;
}
.card1-container.card1 {
    max-width: 350px;
    padding: 40px 40px;
}

.btn {
    font-weight: 700;
    height: 36px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    cursor: default;
}

/*
 * Card component
 */
.card1 {
    background-color: rgba(185, 249, 174, 0.85);
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* shadows and rounded borders */
    border-radius: 2px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card1 {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.form-signin #inputEmail,
.form-signin #inputPassword {
    direction: ltr;
    height: 44px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    box-sizing: border-box;
}

.form-signin .form-control:focus {
    border-color: rgb(0, 162, 38);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(0, 162, 38);
}

.btn.btn-signin {
    /*background-color: #4d90fe; */
    background-color: rgb(0, 162, 38);
    /* background-color: linear-gradient(rgb(0, 162, 38), rgb(0, 162, 38));*/
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    border-radius: 3px;
    border: none;
    transition: all 0.218s;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
    background-color: rgb(9, 63, 22);
}

.forgot-password {
   
    color: rgb(0, 162, 38);
}

.forgot-password:hover,
.forgot-password:active,
.forgot-password:focus{
    /* color: rgb(0, 162, 38); */
    color: rgb(9, 63, 22);
}


.customdatepiker {
  padding: 7px;

}
.card2 {
  margin-top: 55px;
  min-height: 375px;
}
.divbac {
  background: white;
  padding: 18px;
  padding-bottom: 55px;
  border-top: 14px solid #62D27B;
}
.lab {
  margin-left: 9px;
  font-size: 21px;
  margin-right: 6px;
}
.lab1 {
  margin-left: 9px;
  font-size: 21px;
  margin-right: 6px;
}
td.pl2 {
  padding-left: 7px;
}
.cmrgin{
  margin-top: -84px;
}

.bg {
  border-top: 14px solid #62D27B;
  background: white;
  padding: 5px;
}
.custNav {
  padding-top: 16px;
  padding-left: 16px;
}
.iconfont {
  font-size: 28px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.react-datepicker__current-month, .react-datepicker-time__header {
  
  font-size: 12px !important;
}
/* .react-datepicker__month {

  font-size: 11px !important;
} */
.react-datepicker__day-names, .react-datepicker__week {
  font-size: 10px !important;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  font-size: 10px;
}
.react-datepicker__time-container .react-datepicker__time {
  font-size: 10px;
}
.customCFlink:hover,.customCFlink:active,.customCFlink:focus {
text-decoration: underline;
color: green;
cursor: pointer;
}
.secondtableM {
  margin-top: 12vh;
}
.textareacust {
  font-size: 11px;
  padding: 10px;
  margin: 0px;
  margin-right: -26px;
  background: #7f7e7e;
  color: white;
}
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0, 255, 13); 
}
.scondtdmrt{
  /* margin-top: 15px; */
}
.scondtdmrt {
  padding-top: 28px !important;
}
.btn-white {
  background: white;
  border: 1px solid #136f13cf;
  width: 240px
}
.divmanueDrop {
  margin: 10px;
}




button.css-48ayfv {
  width: 100%;
  border: 1px solid #097b09;
}
button.css-1r4vtzz {
  width: 100%;
  border: 1px solid #097b09;
}
.senosrs {
  overflow-y: auto;
  margin-top: 10px;
}
span.senosrs {
  font-size: 11px;
}
.heading {
    background: linear-gradient(to bottom, #0099cc 0%, #66ffcc 100%);
  }
  .reports {
    margin: auto;
    font-family: "Courier New", Courier, monospace;
    font-size: 7vh;
    color: black;
    font-weight: bold;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
.pad {
    display: block;
   
    padding: inherit;
}


.colhblac
{
  color:black !important;
}
.navbar,
.navbar-default {
  background-image: linear-gradient(to bottom,#ffffff 0,#ffffff 100%) !important; 
border-color: #ffffff !important;

}
.navbar-inverse .navbar-nav>li>a>a {
  color:  #41503e !important;
  font-weight: 600;
  font-size: 16px !important;
  
}
.navbar-nav>li>a>a:hover,
.navbar-nav>li>a>a:active,
.navbar-nav>li>a>a:focus {
  color: rgb(34, 169, 63) !important;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: rgb(34, 169, 63);
}
.navbar-inverse .navbar-toggle {
  border-color: rgb(34, 169, 63);
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
  background: rgb(6, 43, 14);
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: rgb(34, 169, 63) !important;
}
.navbar-login
{
    width: 305px ;
    padding: 10px;
    padding-bottom: 0px;
}

.navbar-login-session
{
    padding: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.icon-size
{
    font-size: 87px;
}
.navbar-inverse .navbar-nav>li>a {
  color: #41503e ;
  font-weight: 600;
  font-size: 16px !important;
}
.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:active
 {
   background: #ffffff !important;
     color: rgb(34, 169, 63) !important;
     text-decoration: underline !important;
}
.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
  background: #ffffff !important;
  color: rgb(34, 169, 63) !important;

}
.selectcolor{
/* background: rgba(185, 249, 174, 0.85); */
color:#41503e;
}

.labelcl {
   font-weight: 700;;
   font-size: 17px ;
   color:black;
}
canvas#barchart {
    width: 1100px !important;
    height: 500px !important;
    margin-left: 135px ;
    border: 4px solid darkslategray;
    padding-left: 50px;
    padding-right:100px;
    padding-top: 30px;
    margin-bottom: 40px;
}

.pad {
    border-radius: 21px;
}
.pie {
    padding-left: 35px;
    padding-right: 35px;
}
.polarArea {
    padding-left: 5px;
    padding-right: 5px;
}
.Radar {
    padding-left: 25px;
    padding-right: 25px;
}
.bar {
    padding-left: 35px;
    padding-right: 35px;
}
.doughnut {
    padding-left: 15px;
    padding-right: 15px;
}
.line {
    padding-left: 30px;
    padding-right: 30px;
}
.clmSl{
    width:5%;
}
.color1 {
    background: #62D27B;
    color: white;
}
.color2 {
    background: rgb(155, 161, 147);
    color: white;
}
.color3 {
    background: #D3514F;
     color: white;
}
.labelcust {
    font-size: 22px;
    font-weight: 600;

}
.cust{
    font-size: 13px;
}
.cust-right {
    float: right;
    margin-right: 17px;
}
.ml-3 {
    margin-left: 20px;
}
.mgforForm {
    margin-left: 6px;
}
.mtop-1 {
    margin-top: 10px;
    margin-left: 6px;
}
/*  */
th.text-center.custAction {
    padding-left: 66px !important;
    padding-right: 66px !important;
}
.box.box-danger {
    border-top-color: #dd4b39;
}
.content {
    min-height: 250px;
    padding: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    border-top: 7px solid #62D27B;
}
.box-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.box-header>.fa, .box-header>.glyphicon, .box-header>.ion, .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
}
.bg-aqua, .callout.callout-info, .alert-info, .label-info, .modal-info .modal-body {
    background-color: #00c0ef !important;
}
.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.small-box>.inner {
    padding: 10px;
    width: 255px;
}
.small-box .icon {
    transition: all .3s linear;
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 0;
    font-size: 90px;
    color: rgba(0,0,0,0.15);
}
.small-box>.small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255,255,255,0.8);
    display: block;
    z-index: 10;
    background: rgba(0,0,0,0.1);
    text-decoration: none;
    
}
.bg-green, .callout.callout-success, .alert-success, .label-success, .modal-success .modal-body {
    background-color: #00a65a !important;
}
.bg-red, .callout.callout-danger, .alert-danger, .alert-error, .label-danger, .modal-danger .modal-body {
    background-color: #dd4b39 !important;
}
.bg-yellow, .callout.callout-warning, .alert-warning, .label-warning, .modal-warning .modal-body {
    background-color: #f39c12 !important;
}

.color12{
    color: white;
}
.head{
    font-weight: 900;
    font-size: 32px;
}
.small-box>.small-box-footer:hover {
    color: #fff;
    background: rgba(0,0,0,0.15);
}

.icon i:hover {
    width: 100px;
    height: 100px; /* Safari */
    transition: width 2s;

}
.box1 {
    border-top: 14px solid #62D27B;
    background: white;
  /* padding: 5px; */
}
.btnalig {
    /* text-align: right; */
    float: left;
    margin-top: 25px;
}
.width1 {
    margin: 10px;
}
.sokettablemr {

    margin-top: 5vh;
}
.takenclass {
    color: white;
    font-size: 11px;
}

.chart-container{
    position: relative; 
    height:60vh; 
    width:80vw
}
p.line2 {
    padding-left: 30px;
    padding-right: 30px;
    border: 1px #62D27B solid;
}
select#Page_Size {
    padding: 3px;
    border-radius: 4px;
}
span.spanchart {
    color: #337ab7;
}
/* @media (max-width: 1199px) and (min-width: 769px) {
    .custmDivSensor {
        width: max-content !important;
    }
    .wrapperSenSors {
        width: max-content !important;
    }
} */
@media (max-width: 768px) {
    /* For mobile phones: */
    /* .chart-container {
    
        height:60vh; 
        width:80vw
        
    } */
    .chart-container{
        /* position: relative; 
        height:60vh;  */
        width:92vw !important
    }
    .chartCantainer {
    margin-left:0px ;
    padding-left: 0px;
    }
    /* canvas#barchart1 {
        height: 289px !important;
        width: 362px !important;
    } */
    .ViewBredCum {
        height: 100px;
    } 
    .btnalig {
        /* text-align: right; */
        float: left;
        margin-top: 2px;
        margin-bottom: 20px;
    }
    /* .wrapperSenSors {
        width: max-content !important;
    }
    .custmDivSensor {
        width: max-content;
    } */
  }
.custmcolor1{
background: #00C0EF !important;

}
.custmcolor2{
    background: #2589BD !important;
    
}
    .custmcolor3{
        background: #3D5A80 !important;
     }



     .custmDivSensor {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
    }
    .wrapperSenSors {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important;
        overflow: auto;
    }
    .custmDivSensorUpper {
        max-width: auto;
        overflow-x: auto;
    }

    .dashlastAlert {
        font-size: 18px;
        margin-top: 24px;
        /* margin-bottom: 44px; */
    }
    .fontsizeicon {
        font-size: 84px !important;
        top: -2px !important;
    }
    .ViewBredCum {
        /* margin-top: 10px; */
        background: aliceblue;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 7px;
    }
    .spanBredcum {
        font-weight: bold;
    }
    
.spanBredcumslash {
    margin-left: 5px;
    margin-right: 10px;
    font-weight: 900;
}
.navright {
    display: inline;
    float: right;
}
.spanNev {
    padding: 7px;
    border-radius: 50px;
    border: 1px solid #c1e1ac;
    cursor: pointer;
    background: transparent;
    color: #41503e;
    font-size: 14px !important;
    margin-left: 7px;
    margin-right: 7px;
}

.spanNev:hover,
.spanNev:active {
    background: #e0e3de !important;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
}
.spanNev:focus {
    outline: 0px !important;
}
.criteriaClass {
    color: white;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.chartbtn {
    font-size: 26px !important;
    color: darkgreen;
    padding-bottom: 38px !important;
    padding-top: 1px !important;
    margin-bottom: 6px;
    background: white;
}
.mr-Bootom-2 {
    margin-bottom: 20px;
}
.align-right {
    float: right;
}
.align-center {
    text-align: center;
}
.custmpage {
    margin: 0px !important;
}
.startendTime {
    margin-left: 7px;
    margin-right: 7px;
    width: 80%;
}
.mb-2 {
    margin-bottom: 10px;
}
.ml-5 {
    margin-left: 20px;
}
.disabledClassLabel{
    color:#ccc;
}
.labeltoggal {
    padding-left: 25px;
   
}
.valuetoggal{
/* padding-left: 25px; */


}
.ml-6 {
    margin-left: 40px;
}
.font-12{
    font-size: 12px;
}
.color-grey{
    color: gray;
}
.commentIcon{
    font-size: 15px;
    
}
.spaceinComment {
    padding-left: 17px;
    padding-right: 17px;
}

.outerareaOfComment {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
}
.fontSize16 {
    font-size: 17px;

}
.commentCross{
    color: red !important;
}
.commentCross :hover  {
    color: #93301cde !important;
}
.commentPencel {
    color: black !important;
}
.commentPencel :hover  {
    color: #03030375 !important;
}
.outerContainer {
    max-height: 230px;
    overflow-y: auto;
}
.commentIcon{
    width: 24px;
    height: 22px;
    padding-top: 3px;
    border-radius: 100%;
    text-align: center;
}

.commentIcon:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    width: 24px;
    height: 22px;
    padding-top: 3px;
    border-radius: 100%;
    text-align: center;
}
.commentIcon:active,.commentIcon:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;

    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
    width: 24px;
    height: 22px;
    padding-top: 3px;
    border-radius: 100%;
    text-align: center;
}

.commentBadge {
    /* padding-right: 12px; */
    background: rgb(98, 210, 123);
    /* width: 18px !important;
    height: 18px !important; */
    padding-top: 4px;
    border-radius: 100%;
    color: white;
    font-size: 11px;
    margin-top: -4px;
}
.saveComment {
    color: black;
    margin-right: 5px;
}

.cancelcomment {
    color: black;
    text-decoration: none !important;
}
.commentLeft {
    float: left;
}

.tableactuter {

    margin: 20px 0px;
    max-height: 320px;
    overflow-y: auto;
    
}

/* .dropdown.btn-group.btn-group-primary {
    margin: 20px 30px;
} */
.AcDropS {
    /* color: black; */
    /* margin: 20px 30px; */
    /* background: white; */
}
/* .AcDropS>.open>.dropdown-menu {
    display: block;
    margin-top: -20px;
} */

.red-border{
    border: 1px solid red;
}
.ActiveSensorsRow {
    overflow: auto;

}

.small-boxDActive {
    width: 70px;
    background: white;
    border: 1px solid #069833;
    margin: 4px;
}
.ActiveSheading{
    font-size: 20px;
    text-align: center;
    margin-bottom: 0px;
}
.channelStatus{
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.rowSensors {
    overflow: auto;
}


#wrapper {
    width: 150%;
    height: 90px;
}

#containersimg {
    width: 636px;
    height: 97px;
    overflow-y: hidden !important;
    margin-top: 7px;

}

.square {
 
   float: left;
   height: 90px;
   width: 90px;
   margin: 10px;
}
.widthActive {
    width: 50% !important;
}

.ActivePclass {
  
    font-size: 10.6px;
    text-align: center;
}
.ActiveP {
    background: #62D27B;
    color: white;
    padding-left: 10px;
    margin-bottom: 0px;
}
.ActiveTableH {
    max-height: 291px;
    overflow-y: scroll;
    margin-bottom: 10px;
  
}

.ActiveSelection {
    width: 17%;
    display: inline;
    padding: 4px 9px;
    font-size: 12px;
  } 
  .pagerActive {
    /* padding-left: 0; */
    /* margin: 20px 0; */
    text-align: center;
    list-style: none;
    display: -webkit-inline-box;
    border: 1px solid #ddd;
    padding-left: 17px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 20px;
    padding-right: 17px;
    margin-left: 10px;
    font-size: 12px;
    float: right;
}
  .pagerActive li>a, .pagerActive li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    cursor: pointer;
    color: #337ab7;
    text-decoration: none;
    
}

.Acustmborder{
    border: 1px solid red !important;
}
li.ActiveList {
    color: #337ab7;
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 4px;
}
.collapse.in {
    display: block
  }
  .collapse {
    display: none
  }

  .line3 {
      margin-top: 6px;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px #62D27B solid;
}

  label.ActiveLabel {
    margin-top: 30px !important;
    margin-left: 8px;
}
.ActivedateTime {
    font-size: 10px;
    text-align: center;
    margin-top: -9px;
    margin-bottom: 2px;
}
/* this is slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #62D27B;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #62D27B;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /* this is end slider */
  span.Aidate {
    display: inline-block;
}
input.houremin {
    display: inline-block;
    width: 45px;
    padding: 3px;
    margin-left: 7px;
    font-size: 11px;
}


span.Acolon {
    margin-left: 3px;
    font-size: 18px;
}
.Acustmtd {
    text-align: center;
    margin: 0px;
    padding: 0px;
    font-size: 12px;
}
textarea.ActivetestArea {
    margin: 0px;
    width: 100%;
    height: 300px;
    margin-top: 20px;
    font-size: 14px;
}
.Acustmtd {
    padding: 0px !important;
}
.ActiveSinput {
    margin-left: 44px;
}
.ActiveTableinput {
    padding: 0px !important;
    text-align: center;
}
.Acustmfloat {
    float: right;
    margin-right: -175px;
}

.Apadh{
    padding-left: 40px !important;
    padding-right: 40px !important;
}
.ExecutejobDate {
    display: inline-block !important;
}
.ExecutejobDatepiker {
    padding: 5px;
    border-radius: 4px;
    margin-left: 28px;
}
.modal-90w.modal-dialog {
    width: 90%;
}
/* .OpdLable {
    margin-right: -20px;
} */
.toggleButton {
    margin: 0px;
    padding: 0px;
    background: white;
    border-radius: 10px;
    border-style: none;
}
.AcSetInterval {
    margin-top: 20px;
}
.sensorsDive {
    display: inline-block;
}
.paddForm {
    margin-bottom: 15px;
    margin-top: 15px;
}
.SensorsOuter {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    /* overflow: hidden !important; */
    /* overflow-x: auto; */
}
.SensorsWrapper {
    overflow-x: auto;
}

.ActiveP.textArea {
    margin-bottom: -21px;
    margin-top: 21px;
}
.ActiveCorner {
    background: rgba(185,249,174,.85);
    /* height: 600px; */
    /* margin: -15px; */
    height: 790px;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -15px;
    margin-right: -15px;
}
.ChannelBGColorYellow{
    background: rgba(251, 255, 0, 0.87);
    border: 1px solid #ffc800;
    -webkit-animation-name: example1; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation-name: example1;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}
@-webkit-keyframes example1 {
    
    25%  {background-color:yellow;}
    50%   {background-color:greenyellow;}
  }
  
  /* Standard syntax */
  @keyframes example1 {
   
    25%  {background-color:yellow; }
    50%   {background-color:greenyellow;}
   
  } 
.ChannelBGColortransition {
    background: #04830499;
    color: white;
    border: 1px solid #0d2502;
    -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: infinite; /* Safari 4.0 - 8.0 */
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
    50%  {background-color:green; }
  }
  
  /* Standard syntax */
  @keyframes example {   
    50%  {background-color:green;}
   
  } 
  .onbackground{
      background: rgb(46, 207, 81);
      border: 1px solid rgb(3, 148, 3);
      color: white;
  } 
  .dropdown-toggle.btn.btn-Awhite {
    background: white;
    border: 1px solid #136f13cf;
  
}
.dropDown {
    margin-bottom: 10px;
}
.ActivFilterBtn {
    background: white;
    border: 1px solid #136f13cf;
}
.Mlabel {
    margin-right: 25px;
}
.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #148606;
}
.ClimateTExM{
    margin-top: 0px !important;
}
.rangeClassActive{
    display: inline !important;
    width: 80% !important;
}
.displayRange {
    text-align: center;
    font-size: 11px;
}
.rangeLabel {
    font-size: 11px;
}


  .pointer1{
    cursor: pointer;
  }
  
  
element.style {
}


.input-range__label {
 
    font-size: 16px !important;
    
}
.input-range__label--value {
  top: -25px !important;
}
.displayRange {
  
  margin-bottom: -6px;
  margin-top: 6px

}
.input-range__track--active {
  background: #1ca03e !important;
}
.input-range__slider {

  background: #1ca03e !important;
  border: 1px solid #1ca03e !important;
}
.input-range__track {
  background: #c3faba ;
}
.input-range {
  margin-left: 50px;
  width: 80% !important;
  margin-top: 15px;
  margin-bottom: 10px;
}
.margin-topActive{
    margin-top: 10px
}
/* .nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover {
    color: #fff;
    background: #62D27B;
} */
.fontsizeicon2 {
    font-size: 45px !important;
    top: 16px !important;
}
.ForAlertActive {
    color: white;
    font-size: 11px;
}
.fonsizename {
    font-size: 13px;
}
.spanclass {
    font-size: 12px;
}
.NotActivefont{
    font-weight: 500;
    color: gray;

}
.NotActivefont{
    font-weight: bold;
    color:black;
}
.marginbtnManual {
    margin-bottom:46px  !important
}
.pull-right {
    float: right !important; 
}
.pull-left {
    float: left !important; 
}
.classForsvgManual{
    background-image:url(../../static/media/MANUAL.a320e1ee.svg);
    background-repeat: no-repeat;
}
.classForsvgatomatic{
    background-image:url(../../static/media/automatic.e63ecae3.svg);
    background-repeat: no-repeat;
}

.fontsizeicon12 {
    width: 19px;
    margin-right: 2px;
}
.spanBredDiv {
    margin-top: 8px;
}
td.Acustmtd {
    padding-top: 20px !important;
}
.automaticClas {
    margin-left: 10px;
}
.maualclass {
   
    margin-left: 10px;
}
.margincell {
    margin-right: 100px;
}
@media (max-width: 768px) {
    #containersimg {
        width: 327px ;
    }
    .ActiveCorner {
        /* background: rgba(185,249,174,.85); */
        /* height: 600px; */
        /* margin: -15px; */
        height: 580px;
    }

    #wrapper {
        width: 207%;
    }
  }
  @media (min-width: 992px ) and (max-width : 1199px) {
    .ViewBredCum {
        margin-bottom: 0px;
        border-radius: 7px;
        padding-bottom: 40px;
        padding-top: 4px;
    }
  }
  @media (max-width: 992px ) and (min-width : 768px ) {
    .ViewBredCum {
        margin-bottom: 0px;
        border-radius: 7px;
        padding-bottom: 49px;
        padding-top: 10px;
    }
  }


  .inputFoeldForOffset {
    width: 44px;
    height: 30px;
    padding-top: 0px;
}
.inputforProgram {
    height: 30px;
    width: 171px;
    margin: 10px;
}

.versioninput{
    width: 30px;
    margin-left: 0px !important;
}
.version{
    margin-left: 0px !important;
}

.inputforProgramWef {
    width: 83px !important;
    margin-top:10px;
}
.ProgramLabel {
    margin: 13px;
}
.smalLine {
    border: 0.7px solid #ddd;
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-group-xs>.btn, .btn-xs {
  
    height:  22px;
    /* line-height: 1.428571; */
}
.wefDef {
    display: inline-block;
}

.headermanovr {
    /* padding: 12px !important; */
    font-size: 14px;
        padding-bottom: 15px !important;
}
.margintop {
    margin-top: 30px;
}
.center {
    text-align: center;
}


/* .tableDropDown {
    position: absolute;
} */
.DisplayBloakInMobile {
    display: inline;
}
@media (max-width : 768px){
    .wefDef {
      
        display: block;
    }
    .inputforProgramWef {
        width:100% !important;
        margin-top: 0px;
        margin-left: 10px;
    }
    .CusttableProgram {
       
        overflow-y: auto;
    }
    .Acustmfloat {
        float: none;
         margin-right: none;
       
    }
    .pagerActive {
      
        text-align: center;
        list-style: none;
        display: -webkit-inline-box;
        border: 1px solid #ddd;
        padding-left: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 20px;
        padding-right: 5px;
        margin-left: 0px;
        font-size: 11px;
        float: right;
    }
    .paddForm {
        margin-left: -15px;
        margin-right: -15px;
    
    }
    .DisplayBloakInMobile {
        display: flex;
    }
  }

  .tableDropDown> .dropdown  > .dropdown-menu{
      position: relative !important;
  }
  .hiddenClass {
 display: none;
  }
  .setDeviceTime {
    font-weight: 800;
    text-decoration: underline;
}
.headersetting {
    margin-left: 40px;
}
.App11 {
  font-family: sans-serif;
  text-align: center;
}

.ciclegraph {
  position: relative;
  width: 360px;
  height: 250px;
  margin: calc(100px / 2 + 0px);
}

.ciclegraph:before {
  content: "";
  position: absolute;
  top: -100px;
  left: 275px;
  border: 2px solid teal;
  width: calc(100% - 2px * 2);
  height: calc(60% - 2px * 2);
  border-radius: 50%;
}

.ciclegraph .circle {
  position: relative;
  top: -0%;
  left: 150%;
  width: 50px;
  height: 50px;
  margin: calc(-200px / 2);
  background: rgb(0, 195, 255);
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font: 16px Arial, sans-serif;
}
.ciclegraphTwo {
  position: absolute;
  width: 360px;
  height: 240px;
  margin: calc(100px / 2 + 0px);
  /* background-color: gainsboro; */
}

.ciclegraphTwo:after {
  content: "";
  position: absolute;
  top: -280px;
  left: 275px;
  border: 2px solid teal;
  width: calc(100% - 2px * 2);
  height: calc(60% - 2px * 2);
  border-radius: 50%;
  background-color: rgb(219, 219, 221);
}

.ciclegraphTwo .circleTwo {
  position: absolute;
  top: -60%;
  left: 138%;
  width: 50px;
  height: 50px;
  margin: calc(-200px / 2);
  background: rgb(0, 195, 255);
  border-radius: 50%;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  font: 16px Arial, sans-serif;
  z-index: 1;
}
.tree {
  width: 315px;
  height: 0px;
  z-index: 1;
  top: -85px;
  position: relative;
  left: 465px;
}
.temp {
  width: 50px;
  height: 50;
  top: 28px;
  position: relative;
  left: 120px;
  z-index: 2;
  size: 100px;
  font: 30px Arial, sans-serif;
}

.date {
  width: 800px;
  height: 50;
  top: -65px;
  position: relative;
  left: 90px;
  z-index: 2;
  size: 100px;
  font: 18px Arial, sans-serif;
  padding-bottom: 20px;
}

.depth {
  width: 500px;
  height: 50px;
  top: 50px;
  position: relative;
  left: 405px;
  size: 100px;
  font: 18px Arial, sans-serif;
  background-color: transparent;
  z-index: 1;
}
.depthTwo {
  width: 500px;
  height: 50;
  top: -70px;
  position: relative;
  left: 175px;
  size: 100px;
  font: 18px Arial, sans-serif;
  background-color: transparent;
  z-index: 1;

}

.notify {
  width: 500px;
  height: 50px;
  top: 50px;
  position: relative;
  left: 700px;
  size: 100px;
  font: 25px  Arial, sans-serif;
  font-weight: 'bold';
  background-color: transparent;
  color:#104503;
  z-index: 1;

}

.loginBtn {
  background-color: green;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  
  cursor: pointer;
  text-align: center;
  margin:  2em 8em;
}


.text {
  width: 500px;
  height: 50px;
  top: 50px;
  position: relative;
  left: 550px;
  size: 100px;
  font: 25px  Arial, sans-serif;
  font-weight: 'bold';
  background-color: transparent;
  color:#104503;
  z-index: 1;

}
/* .AlInput {
    width: 32px;
}

.ADInput {
    width: 71px;
    text-align: center;
    padding: 3px;
}
.alertPayloadcustm {
    padding: 6px;
    margin-bottom: 40px;
}
.alertbsName {
    padding: 6px;
    margin: 30px;
}




.slidecontainer {
    width: 100%;
  }
  
  .slider {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  } */
.ml-1 {
    margin-left: 5px;
    margin-right: 5px;
}
.divmanueDrop1 {
    /* margin: 10px; */
    margin-bottom: 15px;
}
.pad-2 {
    padding: 17px;
}
